<template>
	<div>
		<router-link v-if="movie" :to="/materiales/ + movie.slug" class="movieItem">
			<div class="poster"
			:style="movie.poster ? { backgroundImage: 'url(' + movie.poster.data.thumbnails[5].url + ')' } : { backgroundImage: 'url(/assets/img/poster_no_disponible.jpg)' }">
				<div class="play" v-if="showPlay">
					<div class="playIcon"></div>
				</div>
			</div>
			<h3 v-if="showTitle">{{movie.titulo}}</h3>
			<span class="date-tag" v-if="showDateTag">{{movie.fecha_local}}</span>
		</router-link>

		<div v-else class="movieItem">
			<div class="poster loading">
			</div>
			<h3 class="loading" v-if="showTitle"></h3>
			<span class="date-tag loading" v-if="showDateTag"></span>
		</div> 
	</div>               
</template>

<script>
	export default {
		name: 'MovieItem',
		props: {
			showPlay: {
				type: Boolean
			},
			showTitle: {
				type: Boolean
			},
			showDateTag: {
				type: Boolean
			},
			movie: Object
		},
		methods: {
			trimTitle: function(title){
				if(title.length > 15){
					return title.substring(0, 15) + '...'
				}else{
					return title
				}
			}
		}
	}
</script>

<style scoped>
/*-------MEDIDA PREESTABLECIDA PARA POSTERS/ AFICHES---------*/

.movieItem{
	width: 100%;
	display: flex;
	flex-direction: column;   
	flex-shrink: 0;
	text-decoration: none;
	color: black;
}

.movieItem h3{
    font-size: 18px;
    font-weight: 700;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.poster{
	width: 100%;
	padding-top: 142.8%;
	background: #cfcfcf;
	border-radius: 4px;
	position: relative;
	background-size: cover;
	background-position: center;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
}

h3.loading{
	width: 100%;
	height: 25px;
}

.date-tag{
    min-width: 70px;
    min-height: 30px;
    background-color: #fcaf17;
    font-weight: 700;
    font-size: 12px;
    padding: 6px;
    text-transform: uppercase;
    width: max-content;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

@media (max-width: 768px){
	.movieItem h3{
		font-size: 12px;
	}

	.date-tag{
		font-size: 10px;
	}
}

</style>
