
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonBadge,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonText,
  IonButtons,
  IonButton,
  IonMenuToggle,
  IonMenuButton,
  IonItem,
  IonCard,
  IonCardContent,
  IonSkeletonText
} from '@ionic/vue';
import { home, notificationsOutline, chevronForwardOutline, chevronBackOutline  } from 'ionicons/icons';
import MovieItem from '../../components/MovieItem.vue'
import { defineComponent } from 'vue';

// Import Swiper Vue.js components
import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/css/swiper.css';

export default defineComponent({
  name: 'Inicio',
  components: {
    MovieItem,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonBadge,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonText,
    IonButtons,
    IonButton,
    IonMenuToggle,
    IonMenuButton,
    IonItem,
    IonCard,
    IonCardContent,
    IonSkeletonText
  },
  setup(){
    return{
      home,
      notificationsOutline,
      chevronForwardOutline,
      chevronBackOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      nextWeek: Promise as any[any],
      lastUpdated: Promise as any[any],
      notif: [] as any[any],
      token: String,
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){

      if(!(this as any).$store.state.isAuth){
        this.$router.push('/login')
      }

      this.isLoading = true
      const thisThu = this.getThu('this')
      this.nextWeek = await this.getItems('peliculas', 0, 'filter[estado][eq]=publicado&filter[fecha_local][lte]='+thisThu+'&sort=-fecha_local&limit=5')
      this.lastUpdated = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-id')
      
      //notif
      const response = await this.axios.get(this.server + '/items/notificaciones?limit=1&access_token='+(this as any).$store.state.token)

      const items: any = response.data

      //Formatted date
      /*if( items.data.length > 0 ){
        items.data[0].creado = this.getFormatedDate(items.data[0].creado)
      }*/

      this.notif = items

      //create swiper
      const slideOpts = {
        observer: true,
        parentObserver: true,
        slidesPerView: 1,
        spaceBetween: 8,
        centeredSlides: true,
        loop: true,
        grabCursor: true,
        navigation: {
          nextEl: ".nextSlide",
          prevEl: ".prevSlide",
        }
      }

      setTimeout( () => {
          new Swiper(this.$refs.nextWeek as any, slideOpts)
      }, 101)

      this.isLoading = false
    },
    getItems: async function(category: string, limit: number, filter: string) {

      let endpoint: string

      endpoint = '/items/'+category+'?fields=*,portada.*,poster.*,genero.generos_id.nombre'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit > 0 ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)/*.catch( error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      const items: any = response.data

      items.data.forEach((movie: any,index: number) => {
          items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

          if(movie.fecha_local !== null){
            items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
          }else{
            items.data[index].fecha_local = 'A Confirmar'
          }
      })

      return items
    },
    getFormatedDate: function (date: any){
      
      if( isFinite(date) ){
        date = new Date(date+'T12:00:00.000Z')
      }else{
        date = new Date(date.replace(/-/g,'/'))
      }
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    getThu: function(week: string){
      // set dates
      const today = new Date()
      let thisThu = new Date()
      let nextThu = new Date()
      const thuNmb = 4
      let todayNmb= today.getDay()

      // set number for sunday
      if( todayNmb == 0 ){
        todayNmb = 7
      }

      // if not default day number
      if( todayNmb != 4 ){
        const diff = ( todayNmb > thuNmb) ? (todayNmb - thuNmb) * -1 : thuNmb - todayNmb
        thisThu.setDate(today.getDate() + diff)
      }else{
        thisThu = today
      }

      // next thu
      nextThu.setDate(thisThu.getDate() + 7)

      let dd, mm, yyyy

      dd = String(thisThu.getDate()).padStart(2, '0')
      mm = String(thisThu.getMonth() + 1).padStart(2, '0')
      yyyy = thisThu.getFullYear()
      thisThu = yyyy + '-' + mm + '-' + dd as any 

      dd = String(nextThu.getDate()).padStart(2, '0')
      mm = String(nextThu.getMonth() + 1).padStart(2, '0')
      yyyy = nextThu.getFullYear();
      nextThu = yyyy + '-' + mm + '-' + dd as any 

      if( week == 'this'){
        return thisThu
      }else{
        return nextThu
      }
    }
  }
});
