<template>
  <ion-page ref="inicio">
    <!-- HEADER -->
    <ion-header>
      <ion-toolbar color="dark">
        <!-- TITLE -->
        <ion-item class="page-title ion-no-padding" lines="none" color="dark">
          <ion-icon slot="start" size="small" :icon="home" color="dark"></ion-icon>
          <ion-label>
            <ion-text color="primary">
              <h2><b>Inicio</b></h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- BUTTONS -->
        <ion-buttons slot="start">
          <ion-menu-toggle>
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-menu-toggle>
        </ion-buttons>
        <router-link to="/notificaciones" slot="end">
          <ion-button fill="clear" class="ion-no-padding">
            <ion-badge color="primary"></ion-badge>
            <ion-icon :icon="notificationsOutline"></ion-icon>
          </ion-button>
        </router-link>
      </ion-toolbar>
    </ion-header>
    
    <!-- CONTENT -->
    <ion-content :fullscreen="true">
      <div id="container">

        <!-- SLIDER -->
        <section name="nextWeek">
          <div class="container">
            <div class="section-header">
              <div class="section-title">
                Próximos Estrenos
              </div>
              <div class="swiper-navigation">
                <ion-button fill="clear" class="ion-no-padding prevSlide">
                  <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
                </ion-button>
                <ion-button fill="clear" class="ion-no-padding nextSlide">
                  <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
                </ion-button>
              </div>
            </div>
          </div>

          <div class="container" v-if="!isLoading && nextWeek.data">
            <div class="swiper" ref="nextWeek">
              <div class="swiper-wrapper" v-if="nextWeek.data && nextWeek.data.length > 0">
                <div class="swiper-slide" v-for="movie in nextWeek.data" :key="movie.id">
                  <router-link :to="'/materiales/'+movie.slug">
                    <div class="cover">
                      <div class="background-image" :style="(movie.portada) ? { backgroundImage: 'url(' + movie.portada.data.thumbnails[0].url + ')' } : ''"></div>
                      <div class="main-image" :style="(movie.portada) ? { backgroundImage: 'url(' + movie.portada.data.thumbnails[5].url + ')' } : ''" />
                      <div class="text">
                        <h2>{{movie.titulo}}</h2>
                        <ion-button>
                          <ion-text color="dark">
                            Más Info
                          </ion-text>  
                        </ion-button>
                      </div> 
                    </div>
                  </router-link>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          </div>

          <!-- SKELETON -->
          <div class="container" v-else>
            <div class="cover loading"></div>
          </div>
        </section>

        <!-- NOTIFICACIONES -->
        <section name="notif" v-if="notif.data && notif.data.lenght > 0">
          <div class="container">
            <div class="section-header">
              <div class="section-title">
                Notificaciones
              </div>
            </div>
          </div>

          <div class="container">
            <ion-card v-if="notif.data && notif.data.lenght > 0">
              <ion-item lines="none"> 
                <ion-label>
                  <h2><b>{{notif.data[0].titulo}}</b></h2>
                  <span class="ion-hide-sm-up">{{notif.data[0].creado}}</span>
                </ion-label>
                <ion-label class="ion-text-end ion-hide-sm-down">
                  <span>{{notif.data[0].creado}}</span>
                </ion-label>
              </ion-item>

              <ion-card-content>
                {{notif.data[0].mensaje}}
              </ion-card-content>
            </ion-card>

            <ion-card v-else>
              <ion-item lines="none"> 
                <ion-label>
                  <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
                </ion-label>
                <ion-label class="ion-text-end ion-hide-sm-down">
                </ion-label>
              </ion-item>

              <ion-card-content>
                <ion-skeleton-text animated></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              </ion-card-content>
            </ion-card>
          </div>
        </section>

        <!-- MATERIALES -->
        <section name="materials">
          <div class="container">
            <div class="section-title">
              Últimos Materiales
            </div>
            <ion-grid class="ion-no-padding">
              <ion-row v-if="!isLoading && lastUpdated.data">
                <ion-col size-lg="3" size="4" v-for="movie in lastUpdated.data" :key="movie.id">
                  <MovieItem class="ion-margin-bottom" :movie="movie" :showTitle="true" :showDateTag="true"/>
                </ion-col>
              </ion-row>
              <!-- SKELETON -->
              <ion-row v-else>
                <ion-col size-lg="3" size="4" v-for="(i,index) in 8" :key="index">
                  <MovieItem class="ion-margin-bottom" :showTitle="true" :showDateTag="true"/>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </section>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonBadge,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonText,
  IonButtons,
  IonButton,
  IonMenuToggle,
  IonMenuButton,
  IonItem,
  IonCard,
  IonCardContent,
  IonSkeletonText
} from '@ionic/vue';
import { home, notificationsOutline, chevronForwardOutline, chevronBackOutline  } from 'ionicons/icons';
import MovieItem from '../../components/MovieItem.vue'
import { defineComponent } from 'vue';

// Import Swiper Vue.js components
import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/css/swiper.css';

export default defineComponent({
  name: 'Inicio',
  components: {
    MovieItem,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonBadge,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonText,
    IonButtons,
    IonButton,
    IonMenuToggle,
    IonMenuButton,
    IonItem,
    IonCard,
    IonCardContent,
    IonSkeletonText
  },
  setup(){
    return{
      home,
      notificationsOutline,
      chevronForwardOutline,
      chevronBackOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      nextWeek: Promise as any[any],
      lastUpdated: Promise as any[any],
      notif: [] as any[any],
      token: String,
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){

      if(!(this as any).$store.state.isAuth){
        this.$router.push('/login')
      }

      this.isLoading = true
      const thisThu = this.getThu('this')
      this.nextWeek = await this.getItems('peliculas', 0, 'filter[estado][eq]=publicado&filter[fecha_local][lte]='+thisThu+'&sort=-fecha_local&limit=5')
      this.lastUpdated = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-id')
      
      //notif
      const response = await this.axios.get(this.server + '/items/notificaciones?limit=1&access_token='+(this as any).$store.state.token)

      const items: any = response.data

      //Formatted date
      /*if( items.data.length > 0 ){
        items.data[0].creado = this.getFormatedDate(items.data[0].creado)
      }*/

      this.notif = items

      //create swiper
      const slideOpts = {
        observer: true,
        parentObserver: true,
        slidesPerView: 1,
        spaceBetween: 8,
        centeredSlides: true,
        loop: true,
        grabCursor: true,
        navigation: {
          nextEl: ".nextSlide",
          prevEl: ".prevSlide",
        }
      }

      setTimeout( () => {
          new Swiper(this.$refs.nextWeek as any, slideOpts)
      }, 101)

      this.isLoading = false
    },
    getItems: async function(category: string, limit: number, filter: string) {

      let endpoint: string

      endpoint = '/items/'+category+'?fields=*,portada.*,poster.*,genero.generos_id.nombre'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit > 0 ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)/*.catch( error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      const items: any = response.data

      items.data.forEach((movie: any,index: number) => {
          items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

          if(movie.fecha_local !== null){
            items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
          }else{
            items.data[index].fecha_local = 'A Confirmar'
          }
      })

      return items
    },
    getFormatedDate: function (date: any){
      
      if( isFinite(date) ){
        date = new Date(date+'T12:00:00.000Z')
      }else{
        date = new Date(date.replace(/-/g,'/'))
      }
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    getThu: function(week: string){
      // set dates
      const today = new Date()
      let thisThu = new Date()
      let nextThu = new Date()
      const thuNmb = 4
      let todayNmb= today.getDay()

      // set number for sunday
      if( todayNmb == 0 ){
        todayNmb = 7
      }

      // if not default day number
      if( todayNmb != 4 ){
        const diff = ( todayNmb > thuNmb) ? (todayNmb - thuNmb) * -1 : thuNmb - todayNmb
        thisThu.setDate(today.getDate() + diff)
      }else{
        thisThu = today
      }

      // next thu
      nextThu.setDate(thisThu.getDate() + 7)

      let dd, mm, yyyy

      dd = String(thisThu.getDate()).padStart(2, '0')
      mm = String(thisThu.getMonth() + 1).padStart(2, '0')
      yyyy = thisThu.getFullYear()
      thisThu = yyyy + '-' + mm + '-' + dd as any 

      dd = String(nextThu.getDate()).padStart(2, '0')
      mm = String(nextThu.getMonth() + 1).padStart(2, '0')
      yyyy = nextThu.getFullYear();
      nextThu = yyyy + '-' + mm + '-' + dd as any 

      if( week == 'this'){
        return thisThu
      }else{
        return nextThu
      }
    }
  }
});
</script>

<style scoped>
ion-badge{
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 2px;
  padding: 0;
}

.cover{
  width: 100%;
  padding-top: 35%;
  background: black;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

ion-card.profile ion-item{
  border-radius: 4px;
}

@media (max-width: 768px){
  .swiper-slide .background-image{
    display: none;
  }
}

</style>